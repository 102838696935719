import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.svg`
  display: block;
  flex-shrink: 0;
  max-height: 100%;
  max-width: 100%;

  .fill {
    fill: ${({ theme }) => theme.text1};
  }
`

export const InvertIcon: React.FC<{ className?: string }> = (props) => (
  <Wrapper
    className={`invertIcon ${props.className}`}
    height="8"
    viewBox="0 0 10 8"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="fill"
      d="M1.425 88.334h4.827a.508.508 0 0 0 0-1H1.425l.955-1.146a.573.573 0 0 0 0-.707.37.37 0 0 0-.589 0l-1.667 2Q.11 87.5.1 87.517l-.011.018-.013.022-.011.022-.01.02-.009.023-.008.022-.007.022c0 .008 0 .016-.007.024s0 .015 0 .023 0 .016-.005.025 0 .018 0 .026v.022a.6.6 0 0 0 0 .1v.048c0 .008 0 .016.005.025s0 .015 0 .023 0 .016.007.024l.007.022.008.022.009.023.01.02.011.022.013.021.011.018.027.036 1.667 2a.37.37 0 0 0 .589 0 .573.573 0 0 0 0-.707z"
      transform="translate(-.002 -85.334)"
    />
    <path
      className="fill"
      d="M177.239 216.154l.011-.018.013-.021.011-.022.01-.02.009-.023.008-.022c0-.007 0-.015.007-.022s0-.016.007-.024 0-.015 0-.023 0-.016.005-.025 0-.018 0-.026v-.022a.606.606 0 0 0 0-.1v-.022-.026c0-.008 0-.016-.005-.025s0-.015 0-.023 0-.016-.007-.024 0-.015-.007-.022l-.008-.022-.009-.023-.01-.02-.011-.022-.013-.021-.011-.018-.027-.036-1.667-2a.37.37 0 0 0-.589 0 .573.573 0 0 0 0 .707l.955 1.146h-4.827a.508.508 0 0 0 0 1h4.827l-.955 1.146a.573.573 0 0 0 0 .707.37.37 0 0 0 .589 0l1.667-2q.014-.016.027-.034z"
      transform="translate(-167.333 -210.337)"
    />
  </Wrapper>
)

import { css } from 'styled-components'

export const walletConnectModalCSS = css`
  #walletconnect-wrapper {
    .walletconnect-modal__close__line1,
    .walletconnect-modal__close__line2 {
      background-color: rgb(48, 52, 59);
    }
  }
`

export const navItems = [
  {
    title: 'Auctions',
    url: '/overview#topAnchor',
  },
  {
    title: 'Launch Auction',
    url: '/launch-auction',
  },
  {
    title: 'Docs',
    url: '/docs#topAnchor',
  },
]

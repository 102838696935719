import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.svg`
  flex-shrink: 0;
  .fill {
    fill-rule: evenodd;
  }
`

export const CloseCircle: React.FC<{
  className?: string
  color?: string
  width?: string
  style?: any
}> = (props) => {
  return (
    <Wrapper
      className={`alertIcon ${props.className}`}
      height={props.width ? props.width : '18'}
      style={props.style}
      viewBox="0 0 512 512"
      width={props.width ? props.width : '18'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
        fill={props.color}
      />
    </Wrapper>
  )
}
